<template>
  <div v-if="isAuth">
    <b-row>
      <b-col md="4" class="mt-3">
        <b-form-checkbox
            id="checkbox-1"
            v-model="siteStatus"
            name="checkbox-1"
            value="true"
            unchecked-value="false"
            @change="statusClicked">
          Сайт відкрит для Інтернет
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="py-3">
        <b-form-group label-cols-sm="3" label="Пошук" class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" class="m-0"></b-form-input>
            <b-input-group-append>
              <b-button @click="filter = ''; filterYear=0">Очистити</b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-select v-model="filterYear" :options="options" @change="filterYearChange" class="mt-3"></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div class="table-overflow">
          <b-table
            id="my-table"
            :busy.sync="isBusy"
            :items="myProvider"
            :fields="fields"
            :perPage="perPage"
            :filter="filter"
            :current-page="currentPage"
            :key="componentRerender"
            selectable
            select-mode="single"
            @row-clicked="rowClicked"
          >
            <template v-slot:cell(Enabled)="data">
              <div class="h2 mb-0" v-if="data.item.Enabled">
              <b-icon icon="toggle-on"></b-icon>
              </div>
              <div class="h2 mb-0" v-if="!data.item.Enabled">
              <b-icon icon="toggle-off"></b-icon>
              </div>
            </template>
            <div slot="table-busy" class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>{{ $t("loading") }}...</strong>
            </div>
          </b-table>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="d-flex justify-content-center mt-2">
        <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
        ></b-pagination>
      </b-col>
    </b-row>

    <b-row class="mb-4">
      <b-col>
        <b-form-group id="input-group-2" label="Завантаження файла" label-for="input-file">
          Рік:
          <b-form-select v-model="year" :options="options"></b-form-select>
          Тайтл:
          <b-form-input
            id="input-file"
            v-model="newFileTitle"
            :placeholder="$t('profile.titleDescPh')"
            required
          ></b-form-input>
          Опис:
          <b-form-input
            id="input-file"
            v-model="newFileDesc"
            :placeholder="$t('profile.fileDescPh')"
            required
          ></b-form-input>
          Файл:
          <b-form-file accept=".pdf" v-model="newFile"
            :state="Boolean(newFile)"
            :placeholder="$t('profile.uploadFilePh')"
            :drop-placeholder="$t('profile.uploadFile')"
          ></b-form-file>
        </b-form-group>
        <b-button
          type="submit"
          variant="primary"
          @click="UploadFile"
          :disabled="onLoad"
          >{{ $t("profile.upload") }}</b-button
        >
      </b-col>
    </b-row>

    <b-modal hide-footer title="Редагування файла" id="fileeditmodal">
      <b-form-select v-model="modalYear" :options="options"></b-form-select>
      <b-form-input
            id="modal-file-name"
            v-model="modalFileName"
            required
          ></b-form-input>
      <b-form-input
            id="modal-file-title"
            v-model="modalFileTitle"
            required
          ></b-form-input>
      <b-form-input
            id="modal-file-desc"
            v-model="modalFileDesc"
            required
          ></b-form-input>
        Доступ відкрито:
         <b-form-checkbox id="modal-checkbox-1" v-model="modalFileEnabled"
            name="checkbox-1"
            value="true"
            unchecked-value="false"
         ></b-form-checkbox>
      <br/>
      <b-button
          type="submit"
          variant="primary"
          @click="modalOK"
          >Зберегти</b-button>
      &nbsp;
      <b-button
          type="submit"
          variant="secondary"
          @click="modalCancel"
          >Відміна</b-button>
      &nbsp;
      <b-button
          type="submit"
          variant="danger"
          @click="modalDelete"
          >Видалити</b-button>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "MazdaApp",
  data() {
    return {
      isAuth: this.$store.getters.getIsAuthenticated,
      componentRerender: 1,
      submit: false,
      onLoad: true,
      newFile: null,
      newFileDesc: "",
      newFileTitle: "",
      newFileBase64: "",
      isBusy: false,
      perPage: 20,
      currentPage: 1,
      siteStatus: true,
      rows: 20,
      filter: "",
      filterYear: 0,
      totalFiles: 0,
      year: null,
      modalID: 0,
      modalYear: 0,
      modalFileDesc: "",
      modalFileTitle: "",
      modalFileName: "",
      modalFileEnabled: true,
      fields: [
        { key: "Year", sortable: false, label: "Рік" },
        { key: "Filename", sortable: false, label: "Ім'я файла" },
        { key: "Title", sortable: false, label: "Тайтл" },
        { key: "Desc", sortable: false, label: "Опис" },
        { key: "Enabled", sortable: false, label: "Доступ відкрито" },
      ],
      options: [{ value: 0, text: "Рік" }],
    };
  },
  watch: {
    newFile: function (val) {
      var reader = new FileReader();
      var readerthis = this;
      reader.readAsDataURL(val);
      reader.onloadend = function () {
        readerthis.newFileBase64 = reader.result;
        readerthis.onLoad = false;
      };
    },
  },
  methods: {
    myProvider(ctx) {
      ctx.year = this.filterYear;
      return this.$store
        .dispatch("sendAuthenticatedPost", {
          endpoint: "/files/filter",
          sendData: ctx,
        })
        .then((response) => {
          this.rows = response.data.total;
          return response.data.data;
        })
        .catch((error) => {
          console.log("Error", error);
          return [];
        });
    },
    filterYearChange() {
      this.componentRerender ++;
    },
    rowClicked(item) {
      this.modalID = item.ID;
      this.modalYear = item.Year;
      this.modalFileName = item.Filename;
      this.modalFileDesc = item.Desc;
      this.modalFileTitle = item.Title;
      this.modalFileEnabled = item.Enabled;
      this.$bvModal.show("fileeditmodal");
    },
    statusClicked() {
      let sendData = {
        Enabled: JSON.parse(this.siteStatus)
      };
      this.$store
        .dispatch("sendAuthenticatedPost", {
          endpoint: "/site/update",
          sendData: sendData,
        })
        .then(() => {
          this.componentRerender++;
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },
    modalOK() {
      this.$bvModal.hide("fileeditmodal");
      let sendData = {
        ID: this.modalID,
        Desc: this.modalFileDesc,
        Title: this.modalFileTitle,
        Filename: this.modalFileName,
        Enabled: JSON.parse(this.modalFileEnabled),
        Year: parseInt(this.modalYear),
      };
      this.$store
        .dispatch("sendAuthenticatedPost", {
          endpoint: "/files/update",
          sendData: sendData,
        })
        .then(() => {
          this.componentRerender++;
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },
    modalCancel() {
      this.$bvModal.hide("fileeditmodal");
    },
    modalDelete() {
      this.$bvModal.hide("fileeditmodal");
      let sendData = {
        ID: this.modalID,
        Desc: this.modalFileDesc,
        Title: this.modalFileTitle,
        Filename: this.modalFileName,
        Enabled: JSON.parse(this.modalFileEnabled),
        Year: parseInt(this.modalYear),
      };
      this.$store
        .dispatch("sendAuthenticatedPost", {
          endpoint: "/files/delete",
          sendData: sendData,
        })
        .then(() => {
          this.componentRerender++;
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },
    UploadFile() {
      let sendData = {
        Desc: this.newFileDesc,
        Title: this.newFileTitle,
        Filename: this.newFile.name,
        Data: this.newFileBase64,
        Year: parseInt(this.year),
      };
      this.$store
        .dispatch("sendAuthenticatedPost", {
          endpoint: "/files/store",
          sendData: sendData,
        })
        .then(() => {
          this.componentRerender++;
          this.newFileDesc = "";
          this.newFileTitle = "";
          this.newFile.name = "";
          this.newFileBase64 = "";
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },
  },
  mounted() {

    if (this.isAuth == false) {
      this.$router.push('/login')
    }

    let y = new Date().getFullYear();

    for (let i = 2000; i <= y; i++) {
      let obj = JSON.parse('{"value":2020, "text":"2020"}');
      obj.value = i;
      obj.text = i;
      this.options.push(obj);
    }

    this.$store
      .dispatch("sendAuthenticatedGet", { endpoint: "/files/info" })
      .then((response) => {
        this.totalFiles = response.data.total;
        this.siteStatus = response.data.site_enabled;
      })
      .catch((error) => {
        if (error) {
          console.log("Error:", error);
        }
      });
  },
};
</script>

<style>
  .table-overflow {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

</style>
